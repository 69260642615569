import React from "react";
import Logo from "../../components/logo/Logo";
import FooterData from "../../data/Footer/footerItem.json";
import FooterLinkItem from "../../components/Footer/FooterLinkItem.jsx";

const Footer = () => {
  return (
    <div className="footer-section section footer-bg-color">
      <div className="container">
        <div className="row mb-lg-14 mb-md-10 mb-6">
          {/* Logo Section */}
          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mb-6">
            <div className="footer-widget">
              <div className="footer-logo">
                <Logo
                  image={`${process.env.PUBLIC_URL}/images/logo/jci-logo.png`}
                />
              </div>
              <div className="footer-widget-content">
                <div className="content">
                  <p>+976 99027117</p>
                  <p>sg@jcimongolia.mn</p>
                </div>
                <div className="footer-social-inline">
                  <a
                    href="https://www.facebook.com/jcimongolia"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="facebook-hover"
                  >
                    <i className="fab fa-facebook-square" />
                  </a>
                  <a
                    href="https://twitter.com/jcimongolia"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="twitter-hover"
                  >
                    <i className="fab fa-twitter-square" />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCbaIweQnTJBQ5ZpUibjlCeA"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="youtube-hover"
                  >
                    <i className="fab fa-youtube" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Footer Links */}
          {FooterData &&
            FooterData.map((item, index) => (
              <div
                key={index}
                className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-6 mb-6 padding-top"
              >
                <FooterLinkItem data={item} />
              </div>
            ))}

          <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mb-6 footer-dev d-flex flex-column  items-end justify-end">
            {/* IT Partner Section */}
            <div className="footer-widget mb-4  ml-22">
              <h4 className="footer-widget-title text-uppercase font-weight-bold mb-3">
                IT ТҮНШ
              </h4>
              <div className="footer-logo">
                <a
                  href="http://thenewmediagroup.co/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="dark-logo img-fluid"
                    src={`${process.env.PUBLIC_URL}/images/logo/nmg-logo.png`}
                    alt="NMG Logo"
                    style={{ maxWidth: "150px" }}
                  />
                </a>
              </div>
            </div>

            {/* QR Code Section */}
            <div className="qr-code-section ml-22 mt-auto">
              <p className="text-white mb-3 text-uppercase font-weight-bold">
                Апп Татах
              </p>
              <img
                className="img-fluid"
                src={`${process.env.PUBLIC_URL}/images/logo/qr-code.png`}
                alt="QR Code"
                style={{ maxWidth: "200px", margin: "0 auto" }}
              />
            </div>
          </div>
        </div>
        {/* Footer Bottom */}
        <div className="row">
          <div className="col">
            <p className="copyright">
              © 2021 JCI. Бүх эрх хуулиар хамгаалагдсан.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
