import { useState, useEffect, useRef } from "react";
import SectionTitleTwo from "../../components/SectionTitles/SectionTitleTwo";
import Tilt from "react-parallax-tilt";
import Parallax from "parallax-js";
// import { Link } from "react-router-dom";

const AboutFive = () => {
  const [scale] = useState(1.04);
  const sceneEl = useRef(null);
  useEffect(() => {
    const parallaxInstance = new Parallax(sceneEl.current, {
      relativeInput: true,
    });

    parallaxInstance.enable();

    return () => parallaxInstance.disable();
  }, []);

  return (
    <div className="section section-padding-top bg-primary-blue">
      <div className="container">
        <div className="row row-cols-lg-2 row-cols-1 mb-n6">
          <div
            className="col-xl-5 col-lg-6 col-12"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <div className="about-content-area">
              <SectionTitleTwo
                subTitle="Бүхий л мэдээллийг цаг алдалгүй
								нэг дороос"
                title="Мэдээ мэдээлэл"
              />

              <p>
                Байгууллагын дотоод болон гадаад бүхий л мэдээллийг гишүүд бүр
                цаг алдалгүй бүрэн мэдэх боломжтой.
              </p>
              <ul className="mt-4 list-margin">
                <li>Пост</li>
                <li>Зөвхөн гишүүдэд зориулсан тусгай нөхцөл, хямдралууд </li>
                <li>Сургалт</li>
                <li>Талархлын мессеж...</li>
              </ul>
            </div>
          </div>

          <div
            className="col mb-6 pl-xl-12"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <div className="about-image-area faq-image-area">
              <Tilt scale={scale} transitionSpeed={4000}>
                <img
                  src={process.env.PUBLIC_URL + "/images/about/service-8.png"}
                  alt=""
                />
              </Tilt>
              <div className="shape shape-1" id="scene" ref={sceneEl}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutFive;
